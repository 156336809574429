/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/consistent-type-definitions */
import type { PaletteColorOptions } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";

import { defaultTheme as utilifeedTheme } from "@config/utilifeed.theme";

// Base (Custom Colors & Fonts) -----------------------------------------------
// - Primary Brand Color (Blue)
const utfPrimary = {
  main: "#081D23",
  dark: "#081e25",
  light: "#142930",
} as PaletteColorOptions;
// - Alternate Brand Color (Orange)
const utfSecondary = {
  main: "#DB742B",
  dark: "#A45720",
  light: "#E49760",
} as PaletteColorOptions;

// Palette & Typography -------------------------------------------------------
const baseTheme = createTheme(utilifeedTheme, {
  palette: {
    primary: utilifeedTheme.palette.augmentColor({ color: utfPrimary, name: "primary" }),
    secondary: utilifeedTheme.palette.augmentColor({ color: utfSecondary, name: "secondary" }),
  },
});
export const defaultTheme = baseTheme;
