import { createFileRoute } from "@tanstack/react-router";

const PoliciesNewPage = () => {
  // const addNewPolicyMutation = useMutation({
  //   mutationFn: async (policy: Policy) => {
  //     return UserApi.addPolicy(policy);
  //   },
  //   onSettled: () => queryClient.invalidateQueries({ queryKey: ["policies"] }),
  // });
  return null;
};

export const Route = createFileRoute("/app/_layout/profile/policies/new")({
  component: () => <PoliciesNewPage />,
});
