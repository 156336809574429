import { lazy, Suspense } from "react";
import { Box } from "@mui/material";
import { Auth0ContextInterface } from "@auth0/auth0-react";
import { QueryClient } from "@tanstack/react-query";
import { createRootRouteWithContext, Outlet } from "@tanstack/react-router";

const TanStackRouterDevtools =
  process.env.NODE_ENV === "production"
    ? () => null // Render nothing in production
    : lazy(() =>
        // Lazy load in development
        import("@tanstack/router-devtools").then((res) => ({
          default: res.TanStackRouterDevtools,
        }))
      );

const RootComponent = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
      }}
    >
      <Outlet />
      <Suspense>
        <TanStackRouterDevtools />
      </Suspense>
    </Box>
  );
};
export const Route = createRootRouteWithContext<{
  auth: Auth0ContextInterface | undefined;
  queryClient: QueryClient;
}>()({
  component: RootComponent,
});
