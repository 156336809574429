import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createFileRoute, Link, useParams } from "@tanstack/react-router";

import { getNetworksQueryOptions, getPolicyByIdQueryOptions } from "@apis/queries";
import userApi from "@core/apis/user.api";

const SinglePolicyPage = () => {
  const { queryClient } = Route.useRouteContext();
  const { policyId } = useParams({ strict: false });
  const { data: policyData } = useQuery(getPolicyByIdQueryOptions(policyId));
  const { data: allNetworksData } = useQuery(getNetworksQueryOptions());
  const addToPolicyMutation = useMutation({
    mutationFn: async (networkId: string) => {
      return userApi.addNetworkToPolicyById(policyId, networkId);
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["policy", policyId] }),
  });

  return (
    <Box px={4} py={2}>
      <Button
        variant="contained"
        color="primary"
        startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
      >
        <Link to="/app/profile/groups" style={{ color: "white", textDecoration: "none" }}>
          Back to All Groups
        </Link>
      </Button>
      <Typography variant="h2">Policy: {policyData?.name}</Typography>
      <Divider />
      <Grid container spacing={2} p={2}>
        <Grid item xs>
          <Typography variant="h3">Policy found in Groups</Typography>
          <ul>
            {policyData?.groups?.map((group) => (
              <li key={group.uid}>
                <Link to="/app/profile/groups/$groupId" params={{ groupId: group.uid }}>
                  <Typography>{group.name}</Typography>
                </Link>
              </li>
            ))}
          </ul>
        </Grid>
      </Grid>

      <Grid container spacing={2} p={2}>
        <Grid item xs>
          <Typography variant="h3">Networks in Policy</Typography>
          <ul>
            {policyData?.networks?.map((network) => (
              <li key={network.uid}>
                <Typography>{network.name}</Typography>
              </li>
            ))}
          </ul>
        </Grid>
        <Grid item xs>
          <Typography variant="h3">Available Networks</Typography>
          <ul>
            {allNetworksData?.map((network) => (
              <li key={network.uid}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  maxWidth={300}
                  alignItems="center"
                >
                  <Typography>{network.name}</Typography>
                  <Button
                    onClick={() => {
                      addToPolicyMutation.mutate(network.uid);
                    }}
                    variant="contained"
                  >
                    Add to Policy
                  </Button>
                </Box>
              </li>
            ))}
          </ul>
        </Grid>
      </Grid>
      <Divider />
    </Box>
  );
};

export const Route = createFileRoute("/app/_layout/profile/policies/$policyId")({
  component: () => <SinglePolicyPage />,
  loader: async ({ params, context }) => {
    const data = await context.queryClient.ensureQueryData(
      getPolicyByIdQueryOptions(params.policyId)
    );
    return {
      data,
    };
  },
});
