import { faFire } from "@fortawesome/pro-light-svg-icons";
import { createFileRoute } from "@tanstack/react-router";
import { SubscriptionLimits } from "@ui/components";

export const Route = createFileRoute("/app/_layout/")({
  staticData: {
    pageTitle: "App",
    pageIcon: faFire,
  },
  component: SubscriptionLimits,
});
