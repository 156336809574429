import { Box, hexToRgb } from "@mui/material";
import { orange } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import HighchartsReact from "highcharts-react-official";
import * as Highcharts from "highcharts/highstock";
import hcBoost from "highcharts/modules/boost";
import { DateTime } from "luxon";

import { MeterData } from "@models/electricity.models";

hcBoost(Highcharts);

export const ChartNavigator = ({
  chartRef: navigatorChartRef,
  fetchedRange,
  seriesData = [],
  onNavigatorExtremesUpdated,
}: {
  chartRef: any;
  fetchedRange: {
    start: DateTime;
    end: DateTime;
  };
  seriesData: MeterData[] | undefined;
  onNavigatorExtremesUpdated: (start: DateTime, end: DateTime) => void;
}) => {
  const theme = useTheme();

  const series =
    seriesData.length > 0
      ? [
          {
            type: "spline",
            name: "",
            // In turbo we start from the first data point
            pointStart: DateTime.fromISO(seriesData[0].time).toMillis(),
            pointInterval: 3600 * 1000, // We just show hourly data
            data: seriesData.map((row) => {
              return row.power;
            }),
            lineWidth: 0,
            marker: {
              enabled: false,
            },
            states: {
              hover: {
                enabled: false,
              },
            },
            yAxis: 0,
          },
        ]
      : [];

  return (
    <Box
      width="100%"
      sx={{
        borderTop: `${theme.palette.grey["50"]} 1px solid`,
        borderBottom: `${theme.palette.grey["50"]} 1px solid`,
      }}
    >
      <HighchartsReact
        ref={navigatorChartRef}
        highcharts={Highcharts}
        constructorType="stockChart"
        options={{
          boost: {
            enabled: true,
            seriesThreshold: 1,
          },
          chart: {
            id: "navigator",
            height: 28,
            spacing: [0, 0, 0, 5],
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.text.primary,
          },
          navigator: {
            enabled: true,
            height: 15,
            outlineColor: theme.palette.text.primary,
            maskFill: hexToRgb(`${orange[500]}80`),
            xAxis: {
              labels: {
                style: {
                  color: theme.palette.common.white,
                },
              },
            },
          },
          navigation: {
            buttonOptions: {
              enabled: false,
            },
          },
          title: {
            text: "",
          },
          rangeSelector: {
            enabled: false,
          },
          credits: {
            enabled: false,
          },
          legend: {
            enabled: false,
          },
          tooltip: {
            enabled: false,
          },
          yAxis: {
            title: null,
            gridLineWidth: 0,
            visible: false,
            labels: {
              enabled: false,
            },
          },
          xAxis: {
            type: "datetime",
            labels: {
              enabled: false,
            },
            tickLength: 0,
            lineWidth: 0,
            minRange: 1,
            min: fetchedRange.start.toMillis(),
            max: fetchedRange.end.toMillis(),
            events: {
              afterSetExtremes: (e: any) => {
                const start = DateTime.fromMillis(e.min);
                const end = DateTime.fromMillis(e.max);
                onNavigatorExtremesUpdated(start, end);
              },
            },
          },
          series,
        }}
      />
    </Box>
  );
};
