import { FormControl, FormGroup, LinearProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { getPoliciesQueryOptions } from "@apis/queries";
import { useQuery } from "@tanstack/react-query";
import { createFileRoute, Link } from "@tanstack/react-router";

const PoliciesPage = () => {
  const { data: policies, isLoading: policiesLoading } = useQuery(getPoliciesQueryOptions());

  return (
    <Box px={4} py={2}>
      <Typography variant="h3" gutterBottom>
        Policies
      </Typography>
      <FormGroup>
        {policiesLoading && <LinearProgress />}
        {policies?.map((policy) => (
          <FormControl key={policy.uid} sx={{ display: "flex" }}>
            <Link to={`/app/profile/policies/${policy.uid}`}>
              <Typography>{policy.name}</Typography>
            </Link>
          </FormControl>
        ))}
      </FormGroup>
      {/* Add new */}
      <Link to="/app/profile/policies/new">
        <Typography>Add new</Typography>
      </Link>
    </Box>
  );
};

export const Route = createFileRoute("/app/_layout/profile/policies/")({
  component: () => <PoliciesPage />,
  loader: async ({ context }) => {
    // if not authed, we don't need to load anything
    if (!context.auth?.isAuthenticated) {
      return;
    }
    await context.queryClient.ensureQueryData(getPoliciesQueryOptions());
  },
});
