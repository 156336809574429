import type { StateCreator } from "zustand";
import { devtools, type DevtoolsOptions } from "zustand/middleware";

export const zustandMiddlware = <StoreType>(
  f: StateCreator<StoreType, [["zustand/devtools", never]], []>,
  o: DevtoolsOptions
) =>
  devtools(f, {
    ...o,
    anonymousActionType: `${o.name ?? "store"}.anonymous`,
    enabled: !import.meta.env.PROD || import.meta.env.MODE !== "test",
  });
