import {
  Box,
  Button,
  Divider,
  FormControl,
  FormGroup,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import { faChevronLeft, faMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import { createFileRoute, Link } from "@tanstack/react-router";

import {
  getGroupsQueryOptions,
  getPoliciesQueryOptions,
  getUserProfileQueryOptions,
} from "@apis/queries";

const ProfilePage = () => {
  const { auth } = Route.useRouteContext();
  const { data: userProfile, isLoading: userProfileLoading } = useQuery(
    getUserProfileQueryOptions(auth?.user?.sub)
  );
  const { data: groups, isLoading: groupsLoading } = useQuery(getGroupsQueryOptions());
  const { data: policies, isLoading: policiesLoading } = useQuery(getPoliciesQueryOptions());

  return (
    <Box px={4} py={2}>
      <Button
        variant="contained"
        color="primary"
        startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
      >
        <Link to="/app" style={{ color: "white" }}>
          Back to App
        </Link>
      </Button>
      <Box py={2}>
        <Typography variant="h3" gutterBottom>
          Profile
        </Typography>
        <Box>
          {userProfileLoading && <LinearProgress />}
          <Typography variant="h6" gutterBottom>
            Name
          </Typography>
          <Typography>{userProfile?.name}</Typography>
          <Typography variant="h6" gutterBottom>
            Email
          </Typography>
          <Typography>{userProfile?.email}</Typography>
        </Box>
        <Divider />
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography variant="h3" gutterBottom>
              Groups
            </Typography>
            <Box
              flexGrow={1}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              height={200}
            >
              {groupsLoading && <LinearProgress />}
              <FormGroup>
                {groups?.map((group) => (
                  <FormControl key={group.uid} sx={{ display: "flex" }}>
                    <Link to={`/app/profile/groups/${group.uid}`}>
                      <Typography>{group.name}</Typography>
                    </Link>
                  </FormControl>
                ))}
              </FormGroup>
              <Button
                variant="contained"
                color="primary"
                sx={{ width: "100%", maxWidth: 300 }}
                startIcon={<FontAwesomeIcon icon={faMagnifyingGlass} />}
              >
                <Link to="/app/profile/groups" style={{ color: "white", textDecoration: "none" }}>
                  <Typography>View All Groups</Typography>
                </Link>
              </Button>
            </Box>
          </Grid>
          <Grid item xs>
            <Typography variant="h3" gutterBottom>
              Policies
            </Typography>
            <Box
              flexGrow={1}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              height={200}
            >
              {policiesLoading && <LinearProgress />}
              <FormGroup>
                {policies?.map((policy) => (
                  <FormControl key={policy.uid} sx={{ display: "flex" }}>
                    <Link to={`/app/profile/policies/${policy.uid}`}>
                      <Typography>{policy.name}</Typography>
                    </Link>
                  </FormControl>
                ))}
              </FormGroup>
              <Button
                variant="contained"
                color="primary"
                sx={{ width: "100%", maxWidth: 300 }}
                startIcon={<FontAwesomeIcon icon={faMagnifyingGlass} />}
              >
                <Link to="/app/profile/policies" style={{ color: "white", textDecoration: "none" }}>
                  <Typography>View All Policies</Typography>
                </Link>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export const Route = createFileRoute("/app/_layout/profile/")({
  component: ProfilePage,
  beforeLoad: async ({ context }) => {
    // if not authed, we don't need to load anything
    if (!context.auth?.isAuthenticated) {
      return;
    }
    await context.queryClient.ensureQueryData(getUserProfileQueryOptions(context.auth?.user?.sub));
  },
});
