import { StrictMode, useEffect } from "react";
import ReactDOM from "react-dom/client";
import {
  Box,
  CircularProgress,
  createTheme,
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createRouter, RouterProvider } from "@tanstack/react-router";
import * as Highcharts from "highcharts/highstock";
import { DateTime, Settings } from "luxon";

import "@fontsource/roboto/latin-300.css";
import "@fontsource/roboto/latin-400.css";
import "@fontsource/roboto/latin-500.css";
import "@fontsource/roboto/latin-700.css";
import "@fontsource/roboto-condensed/latin-700.css";
import "@fontsource/montserrat/latin-500.css";
import "@fontsource/montserrat/latin-600.css";
import "@fontsource-variable/playfair-display";

import { defaultTheme } from "@ui/theme";

import axiosClient, { CustomAxiosDefaults } from "@core/apis/apiClient";
import useAuthStore from "@core/stores/auth.store";

// Import the generated route tree
import { routeTree } from "./routeTree.gen";

Highcharts.setOptions({
  time: {
    timezone: DateTime.local().zoneName!,
    useUTC: false, // if you enable it, there are no daylight time saving changes.
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 30,
    },
  },
});

// Create a new router instance
const router = createRouter({
  routeTree,
  defaultPendingComponent: () => (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <CircularProgress />
    </Box>
  ),
  defaultErrorComponent: ({ error }) => (
    <div className={`p-2 text-2xl`}>Error: {error.message}</div>
  ),
  context: {
    auth: undefined, // We'll inject this when we render
    queryClient,
  },
  defaultPreload: "intent",
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
});

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Register {
    router: typeof router;
  }
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface StaticDataRouteOption {
    pageTitle?: string;
    pageIcon?: IconDefinition;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onRedirectCallback = async (appState: any) => {
  await router.navigate(appState?.returnTo || window.location.pathname);
};

const RouterComponent = () => {
  const auth = useAuth0();
  const setGetToken = useAuthStore((state) => state.setGetToken);

  useEffect(() => {
    setGetToken(auth.getAccessTokenSilently);
  }, [auth.getAccessTokenSilently, setGetToken]);

  Settings.defaultLocale = "en-GB";

  axiosClient.defaults.baseURL = import.meta.env.REACT_APP_MDSL_V4_URL;
  (axiosClient.defaults as CustomAxiosDefaults).shouldCamelCaseResponse = true;

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={createTheme(defaultTheme)}>
        <CssBaseline />
        {!auth.isLoading ? (
          <RouterProvider
            router={router}
            context={{
              auth,
              queryClient,
            }}
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterComponent />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

// Render the app
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const rootElement = document.getElementById("root")!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  const domain = import.meta.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = import.meta.env.REACT_APP_AUTH0_CLIENTID;
  const audience = import.meta.env.REACT_APP_AUTH0_AUDIENCE;
  root.render(
    <StrictMode>
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        cacheLocation="localstorage"
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience,
        }}
        onRedirectCallback={onRedirectCallback}
      >
        <App />
      </Auth0Provider>
    </StrictMode>
  );
}
