/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { queryOptions } from "@tanstack/react-query";
import { DateTime } from "luxon";

import userApi from "@core/apis/user.api";

import ElectricityApi from "./electricity.api";

const getNetworksQueryOptions = () =>
  queryOptions({
    queryKey: ["networks"],
    queryFn: () => ElectricityApi.getNetworks(),
    retry: 2,
  });

const getSubscriptionLimitsQueryOptions = (networkId: string | undefined) =>
  queryOptions({
    queryKey: ["subscriptionLimits", networkId],
    queryFn: () => ElectricityApi.getSubscriptionLimits(networkId),
    enabled: !!networkId,
  });

const getSLMeterDataQueryOptions = (
  networkId: string | undefined,
  slId: string | undefined,
  start: DateTime | undefined | null,
  end: DateTime | undefined | null,
  options: { enabled: boolean } = { enabled: true }
) =>
  queryOptions({
    queryKey: ["meterData", networkId, slId, start, end],
    queryFn: () =>
      ElectricityApi.getSubscriptionLimitMeterData(networkId, slId, start!.toISO()!, end!.toISO()!),
    enabled: !!(networkId && slId && start && end) && options.enabled,
  });

const getUserProfileQueryOptions = (userId: string | undefined) =>
  queryOptions({
    queryKey: ["userProfile", userId],
    queryFn: () => userApi.getUserProfile(),
    enabled: !!userId,
  });

const getGroupsQueryOptions = () =>
  queryOptions({
    queryKey: ["groups"],
    queryFn: () => userApi.getUserGroups(),
  });

const getPoliciesQueryOptions = () =>
  queryOptions({
    queryKey: ["policies"],
    queryFn: () => userApi.getPolicies(),
  });

const getPolicyByIdQueryOptions = (policyId: string | undefined) =>
  queryOptions({
    queryKey: ["policy", policyId],
    queryFn: () => userApi.getPolicyById(policyId!),
    enabled: !!policyId,
  });
const getGroupByIdQueryOptions = (groupId: string | undefined) =>
  queryOptions({
    queryKey: ["group", groupId],
    queryFn: () => userApi.getGroupById(groupId!),
    enabled: !!groupId,
  });

const addNetworkToPolicyByIdQueryOptions = (
  policyId: string | undefined,
  networkId: string | undefined
) =>
  queryOptions({
    queryKey: ["policy", policyId],
    queryFn: () => userApi.addNetworkToPolicyById(policyId!, networkId!),
    enabled: !!policyId && !!networkId,
  });

const getForecastsQueryOptions = (networkId: string | undefined) =>
  queryOptions({
    queryKey: ["forecasts", networkId],
    queryFn: () => ElectricityApi.getForecasts(networkId),
    enabled: !!networkId,
  });

const getForecastByIdQueryOptions = (
  networkId: string | undefined,
  forecastId: string | undefined
) =>
  queryOptions({
    queryKey: ["forecasts", networkId, "forecast", forecastId],
    queryFn: () => ElectricityApi.getForecastById(networkId, forecastId),
    enabled: !!networkId && !!forecastId,
  });

export {
  getNetworksQueryOptions,
  getSubscriptionLimitsQueryOptions,
  getSLMeterDataQueryOptions,
  getUserProfileQueryOptions,
  getGroupsQueryOptions,
  getPoliciesQueryOptions,
  getPolicyByIdQueryOptions,
  addNetworkToPolicyByIdQueryOptions,
  getGroupByIdQueryOptions,
  getForecastsQueryOptions,
  getForecastByIdQueryOptions,
};
