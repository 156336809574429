import { create } from "zustand";

type State = {
  getToken?: () => Promise<string>;
};

type Action = {
  setGetToken: (getToken: () => Promise<string>) => void;
};

const useAuthStore = create<State & Action>()((set) => ({
  getToken: async () => {
    return "";
  },
  setGetToken: (getToken: () => Promise<string>) => set({ getToken }),
}));

export default useAuthStore;
