export const toCamelCase = (data: object | any[]): object | any[] => {
  if (data === null || typeof data !== "object") {
    return data;
  }

  if (Array.isArray(data)) {
    return data.map((item) => toCamelCase(item));
  }

  const result: { [key: string]: unknown } = {};
  Object.keys(data).forEach((key) => {
    const newKey = key.replace(/([-_][a-z])/gi, ($1) =>
      $1.toUpperCase().replace("-", "").replace("_", "")
    );
    result[newKey] = toCamelCase((data as { [key: string]: any })[key]);
  });

  return result;
};
