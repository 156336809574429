import { useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/login")({
  component: () => <Login />,
});

const Login = () => {
  const search = Route.useSearch();
  const { loginWithRedirect } = useAuth0();

  const handleLogin = () => {
    // This should be moved to auth.login - solId priniciples
    void loginWithRedirect({
      // @ts-expect-error Borked
      appState: { returnTo: search.redirect },
    });
  };

  useEffect(() => {
    // If the user is not authenticated, log them in
    void handleLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography
        variant="h2"
        sx={{
          fontWeight: "bold",
        }}
        pb={4}
      >
        Login to continue
      </Typography>
      <Button onClick={handleLogin} variant="contained">
        Log In
      </Button>
    </Box>
  );
};
