import { useState } from "react";
import {
  Button,
  FormControl,
  FormGroup,
  Grid,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { faAdd, faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createFileRoute, Link } from "@tanstack/react-router";

import { getGroupsQueryOptions } from "@apis/queries";
import userApi from "@core/apis/user.api";

const AllGroupsPage = () => {
  const { queryClient } = Route.useRouteContext();
  const [newGroupName, setNewGroupName] = useState<string>("");
  const { data: groups, isLoading: groupsLoading } = useQuery(getGroupsQueryOptions());

  const addNewGroupMutation = useMutation({
    mutationFn: async () => {
      return userApi.addNewGroup({
        name: newGroupName,
        managementLevel: 4,
        resourceLevel: 4,
      });
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["groups"] }),
  });

  return (
    <Box px={4} py={2}>
      <Button
        variant="contained"
        color="primary"
        startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
      >
        <Link to="/app/profile" style={{ color: "white", textDecoration: "none" }}>
          Back to Profile
        </Link>
      </Button>
      <Grid container py={2} spacing={2}>
        <Grid item xs>
          <Typography variant="h3" gutterBottom>
            All Groups
          </Typography>
          <FormGroup>
            {groupsLoading && <LinearProgress />}
            {groups?.map((group) => (
              <FormControl key={group.uid} sx={{ display: "flex" }}>
                <Link to={`/app/profile/groups/${group.uid}`}>
                  <Typography>{group.name}</Typography>
                </Link>
              </FormControl>
            ))}
          </FormGroup>
        </Grid>
        <Grid item xs>
          <Typography variant="h3">Add New Group</Typography>
          <Stack spacing={2} maxWidth={200}>
            <TextField
              label="Group Name"
              value={newGroupName}
              onChange={(e) => setNewGroupName(e.target.value)}
            />
            <Button
              onClick={() => {
                addNewGroupMutation.mutate();
              }}
              variant="contained"
              color="primary"
              startIcon={<FontAwesomeIcon icon={faAdd} />}
            >
              Add new group
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export const Route = createFileRoute("/app/_layout/profile/groups/")({
  component: () => <AllGroupsPage />,
});
