import { useEffect, useRef, useState, type PropsWithChildren } from "react";
import { Button, Drawer, Grid } from "@mui/material";
import { useTheme, type Theme } from "@mui/material/styles";
import HighchartsReact from "highcharts-react-official";
import { DateTime } from "luxon";

import type { MeterData } from "@models/electricity.models";
import { useDebouncedValue } from "@hooks";
import UtfDateRangePicker from "@shared/ui/inputs/UtfDateRangePicker/UtfDateRangePicker";

import { ChartNavigator } from "./ChartNavigator";

const DRAWER_WIDTH = "100%";
const ROW_HEIGHT = 56;

function openedMixin(theme: Theme) {
  return {
    width: "100%",
    height: `calc(${ROW_HEIGHT} * 2 + 1px)`,
    transition: theme.transitions.create("height", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  };
}

function closedMixin(theme: Theme) {
  return {
    width: "100%",
    height: `calc(${ROW_HEIGHT} + 1px)`,
    transition: theme.transitions.create("height", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
  };
}

function FilterDrawer({ children, ...props }: PropsWithChildren<{ open: boolean }>) {
  const theme = useTheme();
  const paperDefaults = {
    width: DRAWER_WIDTH,
    backgroundColor: theme.palette.primary.main,
    boxSizing: "border-box",
  };
  return (
    <Drawer
      variant="permanent"
      anchor="bottom"
      color="primary"
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        position: "fixed",
        width: DRAWER_WIDTH,
        backgroundColor: theme.palette.primary.main,
        flexShrink: 0,
        ...(props.open && {
          ...openedMixin(theme),
          "& .MuiDrawer-paper": {
            ...paperDefaults,
            ...openedMixin(theme),
            "& > div": {
              ...openedMixin(theme),
            },
          },
        }),
        ...(!props.open && {
          ...closedMixin(theme),
          "& .MuiDrawer-paper": {
            ...paperDefaults,
            ...closedMixin(theme),
            "& > div": {
              ...closedMixin(theme),
            },
          },
        }),
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </Drawer>
  );
}

// const SPEED_OPTIONS = [
//   { label: "text_last_week", type: "week", value: 1, disableMonth: 0.25 }, // 1 week
//   { label: "text_last_month", type: "month", value: 1, disableMonth: 1 }, // 1 month
//   { label: "text_last_3_months", type: "months", value: 3, disableMonth: 3 }, // 3 months
// ];

export const FilterBar = ({
  fetchedRange,
  onExtremesChange,
  registerChart,
  seriesData,
}: {
  fetchedRange: { start: DateTime; end: DateTime }; // This is only used to set the max and min of the chart in Boost mode. No dates are used in the component.
  onExtremesChange: (start: DateTime, end: DateTime) => void;
  registerChart: (key: "primary" | "navigator", ref: any) => void;
  seriesData: MeterData[] | undefined;
}) => {
  const [open] = useState(true);
  const navigatorChartRef = useRef<HighchartsReact.RefObject>(null);
  const [updatedFilterRange, setUpdatedFilterRange] = useState<{
    start: DateTime;
    end: DateTime;
  }>({ start: fetchedRange.start, end: fetchedRange.end });

  const debouncedValue = useDebouncedValue(updatedFilterRange, 600);

  // registerChart("navigator", navigatorChartRef);
  useEffect(() => {
    if (navigatorChartRef.current) {
      registerChart("navigator", navigatorChartRef);
    }
  }, [navigatorChartRef, registerChart]);

  const onResetClick = () => {
    onExtremesChange(fetchedRange.start, fetchedRange.end);
  };

  const shouldShowResetButton =
    // @ts-expect-error ignore
    debouncedValue.start.ts !== fetchedRange.start.ts ||
    // @ts-expect-error ignore
    debouncedValue.end.ts !== fetchedRange.end.ts;

  useEffect(() => {
    if (debouncedValue.start && debouncedValue.end) {
      // If the new range is the same as the fetched range, do nothing
      if (
        // @ts-expect-error ignore
        debouncedValue.start.ts === fetchedRange.start.ts &&
        // @ts-expect-error ignore
        debouncedValue.end.ts === fetchedRange.end.ts
      ) {
        return;
      }
      onExtremesChange(debouncedValue.start, debouncedValue.end);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue, fetchedRange.start, fetchedRange.end]);

  return (
    <FilterDrawer open={open} data-testid="consumption-filterbar">
      <Grid container spacing={1} justifyContent="space-between" alignItems="center" p={1}>
        {/* Highcharts with only navigator enabled */}
        <Grid item xs={9}>
          {fetchedRange.start && fetchedRange.end && (
            <ChartNavigator
              chartRef={navigatorChartRef}
              fetchedRange={fetchedRange}
              // Zoom
              onNavigatorExtremesUpdated={(start, end) => {
                setUpdatedFilterRange({ start, end });
              }}
              seriesData={seriesData}
            />
          )}
        </Grid>
        <Grid item xs={1}>
          {shouldShowResetButton && (
            <Button onClick={onResetClick} variant="outlined" color="secondary">
              Reset
            </Button>
          )}
        </Grid>
        <Grid item xs={2}>
          <UtfDateRangePicker
            minDate={fetchedRange.start}
            maxDate={fetchedRange.end}
            // @ts-expect-error ignore
            onChange={onExtremesChange}
            onDark
          />
        </Grid>
      </Grid>
    </FilterDrawer>
  );
};

FilterBar.displayName = "Consumption.FilterBar";
