import { DateTime } from "luxon";

import axiosClient from "@core/apis/apiClient";
import { MdslApi } from "@core/apis/mdsl.api";
import { Forecast, MeterData, SubscriptionLimit, WeatherData } from "@models/electricity.models";

export class ElectricityApi extends MdslApi {
  async getSubscriptionLimits(networkUid: string | undefined): Promise<SubscriptionLimit[]> {
    if (!networkUid) {
      throw new Error("Network ID is required");
    }
    const authToken = await this.getAuthToken();
    // /electricity/networks/{networkUID}/subscription-limits
    return axiosClient
      .get(`/electricity/networks/${networkUid}/subscription-limits`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => res.data.data.collection);
  }

  async getSubscriptionLimitMeterData(
    networkUid: string | undefined,
    subscriptionLimitUid: string | undefined,
    start_time: string,
    end_time: string
  ): Promise<MeterData[]> {
    if (!networkUid || !subscriptionLimitUid) {
      throw new Error("Network ID and Subscription Limit ID are required");
    }
    const authToken = await this.getAuthToken();

    // /electricity/networks/{networkUID}/subscription-limits/{subscriptionLimitUID}/meter-data
    return axiosClient
      .get(
        `/electricity/networks/${networkUid}/subscription-limits/${subscriptionLimitUid}/meter-data`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          params: {
            start_time,
            end_time,
          },
        }
      )
      .then((res) => res.data.data.collection);
  }

  async getNetworkCoordinates(
    networkUid: string | undefined
  ): Promise<{ lat: number; lon: number }> {
    if (!networkUid) {
      throw new Error("Network ID is required");
    }
    const authToken = await this.getAuthToken();
    // /networks/info-data?uid[in]=networkUid&block_name
    return axiosClient
      .get(`/networks/info-data`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: {
          "uid[in]": networkUid,
          "block_name[in]": "location",
        },
      })
      .then((res) => {
        return {
          lat: res.data.data.collection[0].blockData.coordinates.split(",")[0],
          lon: res.data.data.collection[0].blockData.coordinates.split(",")[1],
        };
      });
  }

  async getWeatherData(
    coordinates: { lat: number | undefined; lon: number | undefined } | undefined,
    start_time: string | undefined | null,
    end_time: string | undefined | null
  ): Promise<WeatherData[]> {
    if (!coordinates || !coordinates.lat || !coordinates.lon) {
      throw new Error("Network coordinates are required");
    }
    if (!start_time || !end_time) {
      throw new Error("Start and end time are required");
    }
    // https://wps.energypredict.se/weather-data/lon/12.4372761/lat/57.7036364?date_min=2023-06-30T22%3A00%3A00&date_max=2024-07-17T21%3A59%3A59&metrics=t
    return axiosClient
      .get(`/weather-data/lon/${coordinates.lon}/lat/${coordinates.lat}`, {
        baseURL: import.meta.env?.REACT_APP_WEATHER_URL,
        headers: {
          Authorization: `WDSL wdsl=NEGEM6HKDM8PQENLOE9OUK3B614A7TZX`,
        },
        params: {
          date_min: start_time,
          date_max: end_time,
          metrics: "t",
        },
      })
      .then((res) => res.data[0].data);
  }

  async getForecasts(networkId: string | undefined): Promise<Forecast[]> {
    if (!networkId) {
      throw new Error("Network ID is required");
    }
    const authToken = await this.getAuthToken();
    // /electricity/networks/{networkUID}/forecasts
    return axiosClient
      .get(`/electricity/networks/${networkId}/forecasts`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => res.data.data.collection)
      .then((data) => {
        return data.map((forecast: any) => {
          return {
            uid: forecast.uid,
            networkId: forecast.networkUid,
            issuedAt: DateTime.fromISO(forecast.issuedAt),
            data: forecast.data,
          };
        });
      });
  }

  async getForecastById(
    networkId: string | undefined,
    forecastUid: string | undefined
  ): Promise<Forecast> {
    if (!networkId || !forecastUid) {
      throw new Error("Network ID and Forecast UID are required");
    }
    const authToken = await this.getAuthToken();
    // /electricity/networks/{networkUID}/forecasts/{forecastUID}
    return axiosClient
      .get(`/electricity/networks/${networkId}/forecasts/${forecastUid}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => res.data.data)
      .then((data) => {
        return {
          uid: data.uid,
          networkId: data.networkUid,
          issuedAt: DateTime.fromISO(data.issuedAt),
          data: data.data,
        };
      });
  }
}

export default new ElectricityApi();
