import { Box, Button, Typography } from "@mui/material";
import { faBowArrow } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createFileRoute, Link } from "@tanstack/react-router";

export const Route = createFileRoute("/")({
  component: Home,
});

function Home() {
  const cover = "./img/backdrop.webp";
  return (
    <Box
      height="100%"
      width="100%"
      display=" flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        color: "white",
      }}
    >
      <Box
        height="100%"
        width="100%"
        display=" flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        zIndex={-1}
        sx={{
          position: "absolute",
          backgroundImage: `url(${cover})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          // darken a bit
          filter: "brightness(0.8)",
        }}
      />
      <Typography variant="h2"> Hello, weary traveler!</Typography>
      <Typography variant="subtitle1" pb={2}>
        Take this, it's dangerous to go alone!
      </Typography>
      {/* Go To App */}
      <Button
        component={Link}
        to="/app"
        variant="contained"
        startIcon={<FontAwesomeIcon icon={faBowArrow} />}
      >
        Take me to the app!
      </Button>
    </Box>
  );
}
