/* eslint-disable react/jsx-props-no-spreading */
import { Divider, Grid, GridOwnProps, Paper, Theme, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import { Box } from "@mui/system";
import clsx from "clsx";

const PREFIX = "HeadingBar";
const classes = {
  root: `${PREFIX}-root`,
  flat: `${PREFIX}-flat`,
  grow: `${PREFIX}-grow`,
  noGrow: `${PREFIX}-noGrow`,
  fullWidth: `${PREFIX}-fullWidth`,
  dark: `${PREFIX}-dark`,
  light: `${PREFIX}-light`,
  addonsRoot: `${PREFIX}-addonsRoot`,
  addonText: `${PREFIX}-addonText`,
};

const Root = styled("div")(({ theme }: { theme: Theme }) => ({
  [`& .${classes.root}`]: {
    minHeight: theme.spacing(7),
    zIndex: 1,
  },
  [`& .${classes.flat}`]: {
    boxShadow: "none",
  },
  [`& .${classes.grow}`]: {
    flexGrow: 1,
  },
  [`& .${classes.noGrow}`]: {
    flexGrow: 0,
  },
  [`& .${classes.fullWidth}`]: {
    width: "100%",
  },
  [`& .${classes.dark}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.grey["50"],
  },
  [`& .${classes.light}`]: {
    backgroundColor: "#fff",
    color: theme.palette.grey["800"],
  },
  [`& .${classes.addonsRoot}`]: {
    flexFlow: "wrap",
    "& > div": {
      width: "auto",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderLeft: 0,
      padding: theme.spacing(0, 2),
      "@media(width <= 1280px)": {
        padding: theme.spacing(0, 1.5),
      },
      borderRight: `2px solid ${theme.palette.grey["100"]}`,
      "&:last-child": {
        paddingRight: 0,
        borderRight: 0,
      },
    },
  },

  [`& .${classes.addonText}`]: {
    "&::after": {
      ...theme.typography.body2,
      content: '"|"',
      padding: "0 4px",
    },
    "&:last-child": {
      "&::after": {
        content: '""',
      },
    },
  },
}));

type Addon = {
  text?: string | number | React.ReactNode;
  icon?: React.ReactNode;
  action?: React.ReactNode;
};

type HeadingBarProps = {
  title: string;
  variant?: "light" | "dark";
  withTitle?: boolean;
  grow?: "grow" | "noGrow";
  titleUpperCase?: boolean;
  flat?: boolean;
  className?: string;
  addons?: Addon[];
  children?: React.ReactNode;
  icon?: React.ReactNode;
  onClick?: any;
} & GridOwnProps;

function HeadingBar({
  variant = "light",
  grow = "grow",
  flat = false,
  children = null,
  title,
  withTitle = true,
  titleUpperCase,
  className = "",
  addons = [],
  icon = null,
  ...props
}: HeadingBarProps) {
  return (
    <Root>
      <Grid
        container
        alignItems="stretch"
        justifyContent="space-between"
        flexWrap="nowrap"
        px={2}
        my={1}
        className={clsx(
          classes.root,
          classes[variant],
          classes[grow],
          flat && classes.flat,
          className
        )}
        component={Paper}
        sx={{ minHeight: 72 }}
        {...props}
      >
        {/* Icon & Title */}
        <Grid item container alignItems="stretch" id="heading-bar-left" flexWrap="nowrap">
          {icon && (
            <Grid item container alignItems="center" xs="auto" mr={2}>
              <Box fontSize={24}>{icon}</Box>
            </Grid>
          )}
          {title && icon && <Divider orientation="vertical" flexItem />}
          {withTitle && (
            <Grid item container alignItems="center" xs ml={icon ? 2 : 0}>
              <Typography
                variant={titleUpperCase ? "h5" : "h6"}
                data-testid={`${title}-heading-bar`}
                sx={{
                  textTransform: titleUpperCase ? "uppercase" : "capitalize",
                  color: titleUpperCase ? "text.primary" : "inherit",
                }}
              >
                {title}
              </Typography>
            </Grid>
          )}
        </Grid>
        {/* Addons */}
        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          className={classes.addonsRoot}
          id="heading-bar-right"
        >
          {children ||
            (addons &&
              addons.filter(Boolean).map((addon, index) => (
                <Grid item key={`addon-${String(index)}`}>
                  {addon.icon && (
                    <Box mt={0.5} mr={addon.text ? 1 : 0}>
                      {addon.icon}
                    </Box>
                  )}
                  {addon.text && (
                    <Typography variant="h6">
                      {Array.isArray(addon.text)
                        ? addon.text.map((text) => (
                            <span
                              key={text}
                              className={classes.addonText}
                              style={{ textTransform: "capitalize" }}
                            >
                              {text}
                            </span>
                          ))
                        : addon.text}
                    </Typography>
                  )}
                  {addon.action && <Box mx={addon.text ? 1 : 0}>{addon.action}</Box>}
                </Grid>
              )))}
        </Grid>
      </Grid>
    </Root>
  );
}

export default HeadingBar;
